import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import IntroBlock from "./IntroBlock"
import MediaBlock from "./MediaBlock"

const PageContainer = styled.div`
  margin-top: 40px;
  position: relative;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 200px;
    margin-bottom: 50px;
  }
`

const PageContents = ({ blocks }) => {
  let pageBlocks = []

  blocks.forEach((block, index) => {
    const { __typename: type } = block
    if (type === "ContentfulTitleTextBlock") {
      pageBlocks.push(<IntroBlock {...block} key={index} />)
    }
    if (type === "ContentfulMediaBlock") {
      pageBlocks.push(<MediaBlock {...block} key={index} />)
    }
  })


  return <PageContainer>{pageBlocks}</PageContainer>
}

export default PageContents
